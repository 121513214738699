import React from "react"
import Seo from "../components/molecules/Seo"
import Container from "../components/atoms/Container"
import {graphql} from "gatsby";
import PrismicRichText from "../components/atoms/PrismicRichText";
import styled from "styled-components";
import Image from "../components/atoms/Image";

const Intro = styled.div`
  width: 100%;
  margin-top: 25vh;
  margin-bottom: 30px;
  @media ( ${props => props.theme.breakpoints.sm} ) {
    width: 50%;
    max-width: 700px;
    margin-top: 140px;
  }

  .contacts {
    font-weight: bold;
    a {
      font-size: inherit;
      @media ( ${props => props.theme.breakpoints.sm} ) {
        font-size: 16px;
      }
    }
    .spacer {
      width: 30px;
      display: inline-block;
    }
  }

  > :first-child { margin-top: 0; }

  > :last-child { margin-bottom: 0; }
`;

const Aoc = styled.div`
  width: 100%;
  max-width: 400px;
  order: 2;
  p {
    font-size: 12px;
    font-style: italic;
    font-weight: 100;
    color: rgba(0,0,0,0.7);
  }
  @media ( ${props => props.theme.breakpoints.sm} ) {
    order: 1;
    margin-top: 100px;
  }
`;

const MediaHolder = styled.div`
  width: 100%;
  order: 1;
  @media ( ${props => props.theme.breakpoints.sm} ) {
    order: 2;
  }
`
const TwoColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  width: 100%;
  max-width: 1000px;
  margin-bottom: 70px;
  @media ( ${props => props.theme.breakpoints.sm} ) {
    grid-template-columns: 1fr 1fr;
  }
`

function AboutPage({data}) {

  const {intro, aoc, video, image} = data.prismicAbout.data;

  return (
    <>
      <Seo title="About" />
      <Container>
        <Intro>
          <PrismicRichText render={intro.richText} />
          <p className="contacts">
            <a href="mailto:mary@studiolibro.com" target="_blank" rel="noopener noreferrer">Email</a>
            <span className="spacer">&nbsp;</span>
            <a href="https://www.instagram.com/studiolibro/" target="_blank"
               rel="noopener noreferrer">Instagram</a>
          </p>
        </Intro>
        <TwoColumn>
          <Aoc>
            <PrismicRichText render={aoc.richText} />
          </Aoc>
          <MediaHolder>
            {video.url ?
              <video playsInline autoPlay muted loop>
                <source src={video.url} type="video/mp4" />
                Sorry, your browser doesn't support embedded videos.
              </video>
              : image.gatsbyImageData &&
              <Image imgName={image.gatsbyImageData} />
            }
          </MediaHolder>
        </TwoColumn>
      </Container>
    </>
  )
}

export default AboutPage;

export const aboutQuery = graphql`
    query AboutQuery {
        prismicAbout {
            data {
                intro {
                    richText
                }
                aoc {
                    richText
                }
                video {
                    url
                }
                image {
                    gatsbyImageData
                    alt
                }
            }
        }
    }
`;
